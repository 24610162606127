import ShopEntertainmentModel from "@/models/services/shop/EntertainmentModel";
import appStore               from "@/stores/appStore";
import { navigate }           from "@widesk/hooks/useNavigate";
import { useEffect }          from "react";
import { useParams }          from "react-router-dom";

const useParamsToEntertainment = () => {
	const { entertainmentId: id } = useParams();
	
	useEffect(() => {
		appStore.changeEntertainment(id);
	}, [id]);
};

const navigateWithEntertainment = (to: string) => {
	const entertainmentId = appStore.entertainment instanceof ShopEntertainmentModel ? appStore.entertainment.id : 'all';
	navigate(`/entertainments/${entertainmentId}${to}`);
};

export { useParamsToEntertainment, navigateWithEntertainment };
