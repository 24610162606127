import View               from '@widesk/components/View';
import useTheme           from '@widesk/hooks/useTheme';
import LayoutHeaderLogout from '@widesk/components/LayoutHeaderLogout';
import ViewPrimary        from '@widesk/components/ViewPrimary';
import { useNavigate }    from 'react-router-dom';
import useThemeMode       from '@widesk/hooks/useThemeMode';
import appStore           from '@/stores/appStore';
import ButtonActions      from '@widesk/components/ButtonActions';
import Img                from '@widesk/components/Img';
import SiderSelectProject from './SiderSelectProject';

export default function LayoutHeader() {
	const { marginLG } = useTheme();
	const navigate = useNavigate();

	return (
		<View row>
			<SiderSelectProject />

			<ViewPrimary
				size={18}
				bold
				paddingH={marginLG}
				separatorL
				separatorProps={{ bg: 'white' }}
				onClick={() => navigate('/')}
				row
				gap="xs"
				minW={250}
			>
				<Img src="/favicon.svg" width={25} preview={false} /> Billetterie
			</ViewPrimary>

			<View row style={{ marginLeft: 'auto' }}>
				<UserHeaderSettings />

				<LayoutHeaderLogout />
			</View>
		</View>
	);
}

const UserHeaderSettings = () => {
	const { mode, setMode } = useThemeMode();
	const { colorPrimary } = useTheme();
	const isDark = mode === 'dark';

	return (
		<View bg={colorPrimary} color="white" center minWidth={150}>
			<ButtonActions
				actions={[
					{
						label: 'Afficher le thème ' + (isDark ? 'clair' : 'sombre'),
						onClick: () => setMode(isDark ? 'light' : 'dark'),
					},
				]}
				icon={appStore.user.renderName({ imageSize: 'middle' })}
				type="text"
				block
				style={{ color: 'white', height: '100%', borderRadius: 0 }}
			/>
		</View>
	);
};
